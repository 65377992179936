import React, { useState, useEffect, useRef } from 'react';
import '../style/pages-style/Circularity.css';
import { translations } from "../app_components/Translation";
import '../style/components-style/CircularitySlider.css';

function CircularitySlider({ selectedLanguage, selectedMaterial, onSliderChange }) {
    
    const initialSliderValues = [
        selectedMaterial.reuse || 0,
        selectedMaterial.recycle || 0,
        selectedMaterial.incineration || 0,
        selectedMaterial.landfill || 0
    ];

    const [sliderValues, setSliderValues] = useState(initialSliderValues);
    const prevSliderValues = useRef(sliderValues);

    useEffect(() => {
        setSliderValues(initialSliderValues);
    }, [selectedMaterial]);

    useEffect(() => {
        if (JSON.stringify(prevSliderValues.current) !== JSON.stringify(sliderValues)) {
            onSliderChange(selectedMaterial.id, sliderValues);
            prevSliderValues.current = sliderValues;
        }
    }, [sliderValues]);

    const handleSliderChange = (index, value) => {
        const newValue = parseInt(value, 10);
        const total = sliderValues.reduce((acc, curr, i) => acc + (i === index ? newValue : curr), 0);

        if (total <= 100) {
            const newSliderValues = [...sliderValues];
            newSliderValues[index] = newValue;
            setSliderValues(newSliderValues);
        } else {
            const remaining = 100 - sliderValues.reduce((acc, curr, i) => acc + (i !== index ? curr : 0), 0);
            const newSliderValues = [...sliderValues];
            newSliderValues[index] = remaining;
            setSliderValues(newSliderValues);
        }
    };

    const sliderTitles = ["Reuse", "Recycle", "Incineration", "Landfill"];

    return (
        <div className="circularity-slider-container">
            <h4>{selectedMaterial.type}<br />{selectedMaterial.category}<br />{selectedMaterial.description}</h4>
            {sliderValues.map((value, index) => (
                <div key={index} className="slider-wrapper">
                    <div className="slider-title-container">
                        <label className="slider-title">{sliderTitles[index]}</label>
                        <span className="slider-value">{value}%</span>
                    </div>
                    <div className="slider-container">
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={value}
                            onChange={(e) => handleSliderChange(index, e.target.value)}
                            className="slider"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CircularitySlider;