import React, {useEffect, useMemo, useState} from "react";
import {translations} from "../app_components/Translation";
import Grid from "../environment-page-components/Grid";
import config from "../config";
import DetailsTableButton from "./DetailsTableButton";
import {useAuth} from "../authentication/AuthProvider";
import ColumnHeaderIcon from "../app_components/ColumnHeaderIcon";

const activityBasedData = {
    'Electricity Grid': {
        'Education': 9.1,
        'Food sales': 59.0,
        'Food service': 40.6,
        'Health care': 23.4,
        'Inpatient': 28.1,
        'Outpatient': 17.5,
        'Lodging': 14.2,
        'Mercantile': 16.4,
        'Retail (other than mall)': 13.1,
        'Enclosed and strip malls': 19.3,
        'Office': 13.4,
        'Public assembly': 11.4,
        'Public order and safety': 13.8,
        'Religious worship': 4.7,
        'Service': 7.4,
        'Warehouse and storage': 5.8,
        'Other': 31.6,
        // 'Vacant': 4.5,
    },
    'Natural Gas': {
        'Education': 30.8,
        'Food sales': 71.4,
        'Food service': 167.7,
        'Health care': 58.8,
        'Inpatient': 80.2,
        'Outpatient': 27.5,
        'Lodging': 38.9,
        'Mercantile': 37.3,
        'Retail (other than mall)': 23.5,
        'Enclosed and strip malls': 46.6,
        'Office': 21.1,
        'Public assembly': 40.8,
        'Public order and safety': 40.5,
        'Religious worship': 24.1,
        'Service': 41.7,
        'Warehouse and storage': 17.7,
        'Other': 30.5,
    }
}

const distanceUnits = ["km", "mi"];
const passengerDistanceUnits = ["passenger-kilometer", "passenger-mile"];
const vehicleDistanceUnits = ["vehicle-mile", 'vehicle-kilometer'];
const areaUnits = ["mm2", "cm2", "dm2", "m2", "km2", "inch2", "ft2"];
const volumeUnits = ["mm3", "cm3", "dm3", "m3", "km3", "L", "ml", "kl", "gal", "fl_oz", "inch3", "ft3", "bbl"];
const weightUnits = ["mg", "g", "kg", "tonne", "oz", "lb"];
const timeUnits = ["s", "min", "h", "d", "wk", "mo", "yr"];
const transportUnits = ["t.km", "t.mi"];
const energyUnits = ["J", "kJ", "GJ", "kWh", "Btu", "mmBTU"];
const itemUnits = ["unit"];
const pieceUnits = ["piece"];

const activityBasedCategories = ['Capital Goods', 'Upstream Leased Assets', 'Downstream Leased Assets', 'Franchises', 'Investments'];

function formatFloatValue(value, decimalPlaces) {
    return Number.parseFloat(value).toFixed(decimalPlaces);
}

function ScopeCategoryDetailsTable({
                                       selectedScopes,
                                       setCategoryDetailsTableGridApi,
                                       setScopeDetailsData,
                                       setSelectedEmissionDetailsData,
                                       setTotalGWP,
                                       pageType,
                                       selectedLanguage
                                   }) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [isOpen, setIsOpen] = useState(true);
    // const [columnDefs, setColumnDefs] = useState([]);
    const [emissionDetailsData, setEmissionDetailsData] = useState([]);
    const [calculationMethods, setCalculationMethods] = useState([]);
    const [typeValues, setTypeValues] = useState([]);
    const [categoryValues, setCategoryValues] = useState([]);
    const [descriptionValues, setDescriptionValues] = useState([]);
    const [unitValues, setUnitValues] = useState([]);
    const {user} = useAuth();
    const userId = user ? user.username : null;
    const [valueChainData, setValueChainData] = useState([]);
    const [organizationData, setOrganizationData] = useState([]);
    const [fleetData, setFleetData] = useState([]);
    const [selectedDetailsRows, setSelectedDetailsRows] = useState([]);
    const selectedText = translations[selectedLanguage].scopeCategoryDetailsTable;
    const [isHovered, setIsHovered] = useState(false);

    const selectedScope = selectedScopes[0];

    const onGridReady = params => {
        setGridApi(params.api);
        setCategoryDetailsTableGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    useEffect(() => {
        if (selectedScope) {
            let methods = []
            if (selectedScope.category === 'Stationary Combustion') {
                methods = ['Fuel based']
            } else if (selectedScope.category === 'Mobile Sources') {
                methods = ['Fuel based', 'Distance based', 'Fleet Based']
            } else if (selectedScope.category === 'Refrigeration / AC Equipment Use') {
                methods = ['Material balance']
            } else if (selectedScope.category === 'Fire Suppression') {
                methods = ['Material balance']
            } else if (selectedScope.category === 'Purchased Gases') {
                methods = ['Purchased based']
            } else if (selectedScope.category === 'Electricity (Location Based)') {
                methods = ['Location based']
            } else if (selectedScope.category === 'Electricity (Market Based)') {
                methods = ['Market based']
            } else if (selectedScope.category === 'Steam') {
                methods = ['Location based']
            } else if (selectedScope.category === 'Business Travel') {
                methods = ['Distance Based']
            } else if (selectedScope.category === 'Employee Commuting') {
                methods = ['Fuel based', 'Distance based']
            } else if (selectedScope.category === 'Upstream Transportation and Distribution') {
                methods = ['Fuel based', 'Distance based', 'Fleet Based']
            } else if (selectedScope.category === 'Downstream Transportation and Distribution') {
                methods = ['Fuel based', 'Distance based', 'Fleet Based']
            } else if (selectedScope.category === 'Purchased Goods and Services') {
                methods = ['Spend based', 'Average data']
            } else if (selectedScope.category === 'Capital Goods') {
                methods = ['Spend based', 'Average data']
            } else if (selectedScope.category === 'Fuel and Energy-related Activities') {
                methods = ['Average data']
            } else if (selectedScope.category === 'Processing of Sold Products') {
                methods = ['Site specific', 'Average data']
            } else if (selectedScope.category === 'Use of Sold Products') {
                methods = ['Direct use-phase', 'Indirect use-phase']
            } else if (selectedScope.category === 'End-of-life Treatment of Sold Products') {
                methods = ['Waste type specific']
            } else if (selectedScope.category === 'Waste') {
                methods = ['Waste-type-specific', 'Average-data']
            } else if (selectedScope.category === 'Franchises') {
                methods = ['Franchise-specific', 'Average-data']
            } else if (selectedScope.category === 'Investments') {
                methods = ['Investment-specific', 'Average-data']
            } else if (selectedScope.category === 'Upstream Leased Assets') {
                methods = ['Asset-specific', 'Lessor-specific', 'Average-data']
            } else if (selectedScope.category === 'Downstream Leased Assets') {
                methods = ['Asset-specific', 'Lessor-specific', 'Average-data']
            } else {
                methods = []
            }
            if (pageType === 'organization') {
                methods = [...methods, 'Value Chain']
                if (activityBasedCategories.includes(selectedScope.category)) {
                    methods = [...methods, 'Activity Based']
                }
            } else if (pageType === 'corporate') {
                methods = [...methods, 'Value Chain', 'Organization']
                if (activityBasedCategories.includes(selectedScope.category)) {
                    methods = [...methods, 'Activity Based']
                }
            }
            setCalculationMethods(methods)
        }
    }, [selectedScope]);

    const refreshEmissionDetailsData = () => {
        if (!selectedScopes || selectedScopes.length === 0) {
            setEmissionDetailsData([]);
            return;
        }
        const scopeTableIDs = selectedScopes.map(scope => scope['id']);
        const fetchPromises = scopeTableIDs.map(scopeID => {
            const url = new URL(`${config.apiUrl}/api/get-emission-details-by-id`);
            url.searchParams.append('scope_id', scopeID);

            return fetch(url.toString(), {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            })
                .then(async response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return await response.json();
                })
                .catch(error => {
                    console.error(`Error fetching data for emission ID ${scopeID}:`, error);
                    return []; // Return an empty array in case of error
                });
        });

        Promise.all(fetchPromises)
            .then(results => {
                const combinedData = results.flat();
                setEmissionDetailsData(combinedData)
                setScopeDetailsData(combinedData)
            })
            .catch(error => {
                console.error('Error fetching data:', error.message);
            });
    };

    const calculateTotalEmission = () => {
        return parseFloat(emissionDetailsData.reduce((total, row) => {
            if (row.totalco2eq && !isNaN(row.totalco2eq)) {
                return total + parseFloat(row.totalco2eq);
            }
            return total;
        }, 0).toFixed(2));  // Adjust the decimal places as needed
    };

    useEffect(() => {
        refreshEmissionDetailsData();
    }, [selectedScopes]);

    useEffect(() => {
        setTotalGWP(calculateTotalEmission())
    }, [emissionDetailsData]);


    const calculateDisplayedTotal = useMemo(() => {
        const rowsToSum = selectedDetailsRows.length > 0 ? selectedDetailsRows : emissionDetailsData;

        let totalGWP = rowsToSum.reduce((total, row) => {
            const gwpValue = parseFloat(row.totalco2eq);
            return !isNaN(gwpValue) ? total + gwpValue : total;
        }, 0);

        return totalGWP.toFixed(2);
    }, [selectedDetailsRows, emissionDetailsData]);

    useEffect(() => {
        if (!gridApi) return;

        const onSelectionChanged = () => {
            const selectedNodes = gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map(node => node.data);
            setSelectedDetailsRows(selectedData);
            setSelectedEmissionDetailsData(selectedData);
        };

        gridApi.addEventListener('selectionChanged', onSelectionChanged);
        return () => {
            gridApi.removeEventListener('selectionChanged', onSelectionChanged);
        };
    }, [gridApi, setSelectedDetailsRows]);

    const fetchValueChainData = () => {
        if (!userId) return;

        const tableName = 'products_services';

        let condition;

        condition = `(user_id = '${userId}')`;

        fetch(`${config.apiUrl}/fetch_data`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                table_name: tableName,
                attribute: '*',
                condition: condition
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => setValueChainData(data || []))
            .catch(error => console.error('Error fetching data:', error));
    };

    const fetchOrganizationData = () => {
        if (!userId) return;

        const tableName = 'organizations';

        let condition;

        condition = `(user_id = '${userId}')`;

        fetch(`${config.apiUrl}/fetch_data`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                table_name: tableName,
                attribute: '*',
                condition: condition
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => setOrganizationData(data || []))
            .catch(error => console.error('Error fetching data:', error));
    };

    const fetchFleetData = () => {
        if (!userId) return;

        const tableName = 'fleets';

        let condition;

        condition = `(user_id = '${userId}')`;

        fetch(`${config.apiUrl}/fetch_data`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                table_name: tableName,
                attribute: '*',
                condition: condition
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => setFleetData(data || []))
            .catch(error => console.error('Error fetching data:', error));
    };

    useEffect(() => {
        fetchValueChainData();
        fetchOrganizationData();
        fetchFleetData();
    }, [gridApi]);

    useEffect(() => {
        if (selectedScope) {
            fetch(`${config.apiUrl}/get_type_dropdown_data_by_emission?emission_type=${selectedScope.category}`)
                .then(response => response.json())
                .then(data => {
                    setTypeValues(data.data || []);
                })
                .catch(error => {
                    console.error('Error fetching type data:', error);
                });
        }
    }, [selectedScope]);

    useEffect(() => {
        const fetchCategoryData = async () => {
            if (emissionDetailsData.length > 0) {
                const categoryValuesMap = await Promise.all(
                    emissionDetailsData.map(async (row) => {
                        const values = await fetchCategoryValues(row.calculation_method, row.type);
                        return {[row.type]: values};
                    })
                );
                setCategoryValues(categoryValuesMap.reduce((acc, cur) => ({...acc, ...cur}), {}));
            }
        };
        fetchCategoryData();
    }, [emissionDetailsData]);

    useEffect(() => {
        const fetchDescriptionData = async () => {
            if (emissionDetailsData.length > 0) {
                const descriptionValuesMap = await Promise.all(
                    emissionDetailsData.map(async (row) => {
                        const values = await fetchDescriptionValues(row.calculation_method, row.type, row.category);
                        return {[`${row.type}-${row.category}`]: values};
                    })
                );
                setDescriptionValues(descriptionValuesMap.reduce((acc, cur) => ({...acc, ...cur}), {}));
            }
        };
        fetchDescriptionData();
    }, [emissionDetailsData]);

    useEffect(() => {
        const fetchUnitData = async () => {
            if (emissionDetailsData.length > 0) {
                const unitValuesMap = await Promise.all(
                    emissionDetailsData.map(async (row) => {
                        const fetchedUnits = await fetchUnitValues(row.type, row.category, row.description);
                        return {[`${row.type}-${row.category}-${row.description}`]: fetchedUnits};
                    })
                );
                setUnitValues(unitValuesMap.reduce((acc, cur) => ({...acc, ...cur}), {}));
            }
        };
        fetchUnitData();
    }, [emissionDetailsData]);

    const fetchCategoryValues = async (calculationMethod, type) => {
        if (calculationMethod === 'Value Chain' || calculationMethod === 'Organization') {
            return ['Scope1', 'Scope2', 'Scope3', 'Scope4'];
        } else if (calculationMethod === 'Fleet Based') {
            return ["Refrigerated Trailers", "Flatbed Trailers", "Dry Van Trailers"];
        } else if (calculationMethod === 'Activity Based') {
            return ['Electricity Grid', 'Natural Gas'];
        } else {
            try {
                const url = new URL(`${config.apiUrl}/get_emission_category_dropdown_data`);
                url.searchParams.append('type', type);
                url.searchParams.append('calculationMethod', calculationMethod);

                const response = await fetch(url.toString());
                const data = await response.json();
                return data.data || [];
            } catch (error) {
                console.error('Error fetching category values:', error);
                return [];
            }
        }
    };


    const fetchDescriptionValues = async (calculationMethod, type, category) => {
        if (calculationMethod === 'Value Chain') {
            return valueChainData
                .filter(valueChain => valueChain.role === type)
                .map(valueChain => valueChain.product_service)
                .filter((value, index, self) => value && self.indexOf(value) === index);
        } else if (calculationMethod === 'Organization') {
            return organizationData
                .filter(organization => organization.org_type === type)
                .map(organization => organization.organization)
                .filter((value, index, self) => value && self.indexOf(value) === index);
        } else if (calculationMethod === 'Fleet Based') {
            return fleetData
                .filter(fleet => fleet.vehicle_type === type && fleet.trailer_type === category)
                .map(fleet => fleet.fleet)
                .filter((value, index, self) => value && self.indexOf(value) === index);
        } else if (calculationMethod === 'Activity Based') {
            try {
                const response = await fetch(`${config.apiUrl}/get_activity_based_description_dropdown_data?category=${category}`);
                const data = await response.json();
                return data.data || []
            } catch (error) {
                console.error('Error fetching activity based description values:', error);
                return []
            }
        } else {
            try {
                const response = await fetch(`${config.apiUrl}/get_emission_description_dropdown_data?type=${type}&category=${category}`);
                const data = await response.json();
                return data.data || []
            } catch (error) {
                console.error('Error fetching description values:', error);
                return []
            }
        }
    };

    const fetchUnitValues = async (type, category, description) => {
        const matchUnits = (unit) => {
            if (distanceUnits.includes(unit)) {
                return distanceUnits;
            } else if (passengerDistanceUnits.includes(unit)) {
                return passengerDistanceUnits;
            } else if (vehicleDistanceUnits.includes(unit)) {
                return vehicleDistanceUnits;
            } else if (areaUnits.includes(unit)) {
                return areaUnits;
            } else if (volumeUnits.includes(unit)) {
                return volumeUnits;
            } else if (weightUnits.includes(unit)) {
                return weightUnits;
            } else if (timeUnits.includes(unit)) {
                return timeUnits;
            } else if (transportUnits.includes(unit)) {
                return transportUnits;
            } else if (energyUnits.includes(unit)) {
                return energyUnits;
            } else if (itemUnits.includes(unit)) {
                return itemUnits;
            } else if (pieceUnits.includes(unit)) {
                return pieceUnits;
            } else {
                return ["kg CO2"];
            }
        };

        try {
            const response = await fetch(`${config.apiUrl}/get_emission_unit_data?type=${type}&category=${category}&description=${description}`);
            const data = await response.json();
            return matchUnits(data.data);
        } catch (error) {
            console.error('Error fetching emission unit:', error);
            return []
        }
    }

    const fetchGWPUnit = async (calculationMethod, type, category, description) => {
        if (['Scope1', 'Scope2', 'Scope3', 'Scope4'].includes(category)) {
            try {
                const response = await fetch(`${config.apiUrl}/fetch-scope-gwp-unit`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        calculationMethod: calculationMethod,
                        type: type,
                        category: category,
                        description: description,
                    })
                });

                const data = await response.json();
                return parseFloat(data.data);
            } catch (error) {
                console.error('Error fetching gwp unit:', error);
                throw error;
            }
        } else if (calculationMethod === 'Fleet Based') {
            const fleet = fleetData.find(fleet => fleet.fleet === description);
            console.log(fleet.fuel_consumed)
            return fleet.fuel_consumed > 0 ? fleet.fuel_consumed * 2.62 : 0.0
        } else if (calculationMethod !== 'Activity Based') {
            try {
                const response = await fetch(`${config.apiUrl}/fetch-gwp-unit`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: type,
                        category: category,
                        description: description,
                    })
                });

                const data = await response.json();
                return parseFloat(data.data);
            } catch (error) {
                console.error('Error fetching gwp unit:', error);
                throw error;
            }
        } else {
            if (category === 'Electricity Grid') {
                try {
                    const response = await fetch(`${config.apiUrl}/fetch-gwp-unit`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            type: 'Energy',
                            category: category,
                            description: description,
                        })
                    });

                    const data = await response.json();
                    return parseFloat(data.data) * activityBasedData[category][type];
                } catch (error) {
                    console.error('Error fetching gwp unit:', error);
                    throw error;
                }
            } else {
                return 0.21 * activityBasedData[category][type];
            }
        }
    }

    const emissionUnitConvert = async (toUnit, type, category, description) => {
        try {
            const response = await fetch(`${config.apiUrl}/convert-emission-unit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    toUnit: toUnit,
                    type: type,
                    category: category,
                    description: description,
                })
            });

            const data = await response.json();
            return parseFloat(data.data);
        } catch (error) {
            console.error('Error converting emission unit:', error);
            throw error;
        }
    }

    const cellValueChanged = async (params) => {
        if (params.colDef.field === 'type' && params.oldValue !== params.newValue) {
            // Fetch new category values based on the updated type
            const newCategoryValues = await fetchCategoryValues(params.data.calculation_method, params.data.type);
            setCategoryValues((prev) => ({
                ...prev,
                [params.data.type]: newCategoryValues,
            }));

            // If the category already has a value, fetch the corresponding description values
            if (params.data.category) {
                const newDescriptionValues = await fetchDescriptionValues(params.data.calculation_method, params.data.type, params.data.category);
                setDescriptionValues((prev) => ({
                    ...prev,
                    [`${params.data.type}-${params.data.category}`]: newDescriptionValues,
                }));
            }

            if (params.data.calculation_method === 'Activity Based') {
                if (params.data.category && params.data.description) {
                    params.data.gwp_unit = await fetchGWPUnit(params.data.calculation_method, params.data.type, params.data.category, params.data.description);
                    params.data.totalco2eq = params.data.gwp_unit * params.data.quantity * params.data.factor * params.data.uncertainty;
                    gridApi.refreshCells({rowNodes: [params.node], force: true});
                    setTotalGWP(calculateTotalEmission());
                }
            }
        }

        // Handle changes in the 'category' field
        if (params.colDef.field === 'category' && params.oldValue !== params.newValue) {
            // Fetch new description values based on the updated category
            if (params.data.calculation_method === 'Value Chain' || params.data.calculation_method === 'Organization') {
                if (params.data.description) {
                    params.data.gwp_unit = await fetchGWPUnit(params.data.calculation_method, params.data.type, params.data.category, params.data.description);
                    gridApi.refreshCells({rowNodes: [params.node], force: true});
                    params.data.totalco2eq = params.data.gwp_unit * params.data.quantity * params.data.factor * params.data.uncertainty
                    setTotalGWP(calculateTotalEmission());
                }
            }
            const newDescriptionValues = await fetchDescriptionValues(params.data.calculation_method, params.data.type, params.data.category);
            setDescriptionValues((prev) => ({
                ...prev,
                [`${params.data.type}-${params.newValue}`]: newDescriptionValues,
            }));
        }
        if (params.colDef.field === 'description') {
            const newUnitValues = await fetchUnitValues(params.data.type, params.data.category, params.data.description);
            setUnitValues((prev) => ({
                ...prev,
                [`${params.data.type}-${params.data.category}-${params.newValue}`]: newUnitValues,
            }));
            params.data.gwp_unit = await fetchGWPUnit(params.data.calculation_method, params.data.type, params.data.category, params.newValue);
            gridApi.refreshCells({rowNodes: [params.node], force: true});
        }

        if ((params.colDef.field === 'quantity' || params.colDef.field === 'factor' || params.colDef.field === 'uncertainty') && params.oldValue !== params.newValue) {
            if (params.data.calculation_method !== 'Value Chain' && params.data.calculation_method !== 'Organization' && params.data.calculation_method !== 'Activity Based' && params.data.calculation_method !== 'Fleet Based') {
                try {
                    if (params.data.quantity && params.data.factor && params.data.gwp_unit && params.data.uncertainty) {
                        params.data.totalco2eq = params.data.quantity * params.data.factor * params.data.gwp_unit * params.data.uncertainty;
                    } else {
                        params.data.totalco2eq = 0;
                    }

                    // Refresh the cell to show the updated total_CO2 value
                    gridApi.refreshCells({rowNodes: [params.node], force: true});
                    setTotalGWP(calculateTotalEmission());

                } catch (error) {
                    console.error('Error calculating total emission:', error);
                }
            } else {
                try {
                    params.data.totalco2eq = await fetchGWPUnit(params.data.calculation_method, params.data.type, params.data.category, params.data.description) * params.data.quantity * params.data.factor * params.data.uncertainty;
                    gridApi.refreshCells({rowNodes: [params.node], force: true});
                    setTotalGWP(calculateTotalEmission());
                } catch (error) {
                    console.error('Error calculating total emission:', error);
                }
            }
        }

        if (params.colDef.field === 'unit' && params.oldValue !== params.newValue) {
            if (params.data.calculation_method !== 'Value Chain' && params.data.calculation_method !== 'Organization' && params.data.calculation_method !== 'Activity Based') {
                try {
                    const unitConversionMagnitude = await emissionUnitConvert(params.newValue, params.data.type, params.data.category, params.data.description);

                    params.data.gwp_unit = unitConversionMagnitude;
                    params.data.totalco2eq = params.data.quantity * unitConversionMagnitude;

                    gridApi.refreshCells({rowNodes: [params.node], force: true});
                    setTotalGWP(calculateTotalEmission());
                } catch (error) {
                    console.error('Error converting emission unit:', error);
                }
            }
        }
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.addEventListener('cellValueChanged', cellValueChanged);
        }

        return () => {
            if (gridApi) {
                gridApi.removeEventListener('cellValueChanged', cellValueChanged);
            }
        };
    }, [gridApi, categoryValues, descriptionValues, unitValues]);


    const columnDefs = [{
        headerName: selectedText.calculationMethod,
        field: 'calculation_method',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: {
            values: calculationMethods,
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        minWidth: 150,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.type,
        field: 'type',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: (params) => {
            if (params.data.calculation_method === 'Value Chain') {
                return {values: ['Supplier', 'Subsidiary', 'Affliate', 'PartnerShip/JV', 'Franchises', 'Independent']}
            } else if (params.data.calculation_method === 'Organization') {
                return {values: ['Equity Share', 'Operational Control', 'Financial Control']}
            } else if (params.data.calculation_method === 'Fleet Based') {
                return {values: ["Highway Trucks", "City Delivery Trucks", "Heavy-Duty Trucks", "Electric Trucks", "CNG Trucks", "Passenger Buses", "Electric Buses", "Garbage Trucks", "Cement Mixer Trucks", "Mining Trucks"]}
            } else if (params.data.calculation_method === 'Activity Based') {
                return {values: Object.keys(activityBasedData['Electricity Grid'] || {})}
            } else {
                return {values: typeValues};
            }
        },
        minWidth: 150,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.category,
        field: 'category',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: (params) => {
            const values = categoryValues[params.data.type] || [];
            return {values: values};
        },
        minWidth: 175,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.description,
        field: 'description',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: (params) => {
            const key = `${params.data.type}-${params.data.category}`;
            const values = descriptionValues[key] || [];
            return {values: values};
        },
        minWidth: 175,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.quantity,
        field: 'quantity',
        filter: false,
        maxWidth: 100,
        cellEditor: 'agNumberCellEditor',
        editable: true,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        cellStyle: {textAlign: 'right'},
        headerClass: "ag-right-aligned-header"
    }, {
        headerName: selectedText.unit,
        field: 'unit',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: (params) => {
            if (params.data.calculation_method !== 'Activity Based') {
                const key = `${params.data.type}-${params.data.category}-${params.data.description}`;
                const values = unitValues[key] || [];
                return {values: values};
            } else {
                if (params.data.category === 'Electricity Grid') {
                    return {values: ['sq ft']};
                } else if (params.data.category === 'Natural Gas') {
                    return {values: ['sq ft']};
                } else {
                    return {values: []};
                }

            }
        },
        minWidth: 100,
        maxWidth: 100,
        cellStyle: {textAlign: 'left'},
    }, {
        headerName: selectedText.factor,
        field: 'factor',
        headerComponent: ColumnHeaderIcon,
        filter: false,
        minWidth: 100,
        maxWidth: 100,
        cellEditor: 'agNumberCellEditor',
        editable: true,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        cellStyle: {textAlign: 'right'},
        headerClass: "ag-right-aligned-header"
    }, {
        headerName: selectedText.uncertainty,
        field: 'uncertainty',
        filter: false,
        minWidth: 100,
        maxWidth: 150,
        cellEditor: 'agNumberCellEditor',
        editable: true,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        cellStyle: {textAlign: 'right'},
        headerClass: "ag-right-aligned-header"
    }, {
        headerName: selectedText.gwpUnit,
        field: 'gwp_unit',
        filter: false,
        editable: false,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        minWidth: 125,
        cellStyle: {textAlign: 'right'},
        headerClass: "ag-right-aligned-header"
    }, {
        headerName: selectedText.TotalCO2Eq,
        field: 'totalco2eq',
        filter: false,
        editable: false,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        minWidth: 125,
        cellStyle: {textAlign: 'right'},
        headerClass: "ag-right-aligned-header"
    },]

    const envFactorGridProps = {
        columnDefs: columnDefs,
        rowData: emissionDetailsData,
        rowSelection: "multiple",
        onGridReady,
    }

    const toggleContainer = () => setIsOpen(!isOpen);

    return (<div className={`env-factor-table ${isOpen ? 'expanded' : ''}`}>
        <div className="container-header">
            <h2
                onClick={toggleContainer}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    cursor: 'pointer',
                    color: isHovered ? 'grey' : 'black', // Change 'blue' to your desired hover color
                }}
            >{selectedText.scopeDetails}</h2>
        </div>
        {isOpen && (<div className="grid-container-env">
            <div className="grid-wrapper env-factor-grid-wrapper">
                <Grid {...envFactorGridProps} customMinHeight={264}/>
            </div>
            <div className="gwp-total-env">
                <span className="gwp-total-env-text">Total: </span>
                <span className="gwp-total-env-number">{calculateDisplayedTotal}</span>
            </div>
            <div className="button-container-env">
                <DetailsTableButton selectedScope={selectedScope} selectedDetails={selectedDetailsRows}
                                    tableGridApi={gridApi} refreshData={refreshEmissionDetailsData}
                                    selectedLanguage={selectedLanguage}/>
            </div>
        </div>)}
    </div>);

}

export default ScopeCategoryDetailsTable;