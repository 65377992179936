import React, {useMemo, useState, useEffect, useCallback, useRef} from 'react';
import Select from 'react-select';
import Header from "../app_components/Header";
import PhaseCard from "../comparison-components/PhaseCard";
import "../style/pages-style/Comparison.css";
import {Bar} from "react-chartjs-2";
import {useTable} from "react-table";
import config from '../config';
import {useAuth} from "../authentication/AuthProvider";
import {translations} from "../app_components/Translation";
import {FiDownload} from "react-icons/fi";
import {Icon} from "@iconify/react";
import {saveAs} from 'file-saver';
import {Document, Packer, Paragraph, ImageRun, TextRun, Table, TableRow, TableCell, Footer} from 'docx';

function OrganizationalComparison({selectedLanguage}) {
    const [comparisonType, setComparisonType] = useState("");
    const [comparisonBasedOn, setComparisonBasedOn] = useState("");
    const [selectedValues, setSelectedValues] = useState([]);
    const [comparisonBasedOnOptions, setComparisonBasedOnOptions] = useState([]);
    const [footprintSummaryData, setFootprintSummaryData] = useState([]);
    const [selectedComparisonData, setSelectedComparisonData] = useState({});
    const [percentageDifferences, setPercentageDifferences] = useState({});
    const {user} = useAuth();
    const userId = user ? user.username : null;
    const barChartRef = useRef(null);
    const footprintChartRef = useRef(null); // New ref for footprint graph
    const [barGraphGWPUnit, setBarGraphGWPUnit] = useState('kg');

    const selectedText = translations[selectedLanguage]?.comparison || {};

    const comparisonTypeOptions = [
        {value: 'Value Chain', label: selectedText.supply_chain || 'Value Chain'},
        {value: 'Organization', label: selectedText.organization || 'Organization'},
        {value: 'Corporate', label: selectedText.corporate || 'Corporate'}
    ];

    const phaseKeys = {
        [selectedText.scope1 || 'Scope1']: 'scope1',
        [selectedText.scope2 || 'Scope2']: 'scope2',
        [selectedText.scope3 || 'Scope3']: 'scope3',
        [selectedText.scope4 || 'Scope4']: 'scope4',
    };

    const fetchData = useCallback(() => {
        if (!userId || !comparisonType) return;

        console.log("Fetching data with", {comparisonType, userId});

        fetch(`${config.apiUrl}/get_product_or_design_name?comparison_type=${comparisonType}&user_id=${userId}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
            .then(response => response.json())
            .then(data => {
                console.log("Fetched data:", data);

                if (data.error) {
                    throw new Error(data.error);
                }

                if (data.results && data.results.length > 0) {
                    const options = data.results.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                    setComparisonBasedOnOptions(options);
                    console.log("Options set:", options);
                } else {
                    setComparisonBasedOnOptions([]);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [comparisonType, userId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleApply = () => {

        const allSelectedValues = [...selectedValues];
        if (!allSelectedValues.includes(comparisonBasedOn)) {
            allSelectedValues.push(comparisonBasedOn);
        }

        const queryParams = new URLSearchParams({
            comparison_type: comparisonType,
            selected_values: allSelectedValues.join(',')
        });

        fetch(`${config.apiUrl}/get_gwp_per_scope?${queryParams.toString()}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
            .then(response => response.json())
            .then(data => {
                console.log('GWP per scope data:', data);

                const formattedData = Object.entries(data).map(([rowId, phases]) => {
                    const productOption = comparisonBasedOnOptions.find(option => option.value === rowId);
                    const productName = productOption ? productOption.label : rowId;

                    return {
                        product: productName,
                        scope1: parseFloat(phases['Scope1'] || 0).toFixed(2),
                        scope2: parseFloat(phases['Scope2'] || 0).toFixed(2),
                        scope3: parseFloat(phases['Scope3'] || 0).toFixed(2),
                        scope4: parseFloat(phases['Scope4'] || 0).toFixed(2),
                        total: parseFloat(
                            (phases['Scope1'] || 0) +
                            (phases['Scope2'] || 0) +
                            (phases['Scope3'] || 0) +
                            (phases['Scope4'] || 0)
                        ).toFixed(2)
                    };
                });

                setFootprintSummaryData(formattedData);

                const selectedData = formattedData.find(item => item.product === comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)?.label);
                setSelectedComparisonData(selectedData || {});

                const percentageDifferences = {};
                formattedData.forEach(item => {
                    if (item.product !== selectedData.product) {
                        percentageDifferences[item.product] = percentageDifference(parseFloat(item.total), parseFloat(selectedData.total));
                    }
                });

                setPercentageDifferences(percentageDifferences);
            })
            .catch(error => {
                console.error('Error fetching scope GWP data:', error);
            });
    };

    const footprintSummaryColumns = useMemo(() => [
        {
            Header: comparisonType === 'Value Chain' ? (selectedText.product || 'Product') : (selectedText.organization || 'Organization'),
            accessor: 'product'
        },
        {
            Header: selectedText.scope1 || 'Scope1',
            accessor: 'scope1',
            Cell: ({value}) => `${value}`,
            style: {backgroundColor: '#79AC78'}
        },
        {
            Header: selectedText.scope2 || 'Scope2',
            accessor: 'scope2',
            Cell: ({value}) => `${value}`,
            style: {backgroundColor: '#B0D9B1'}
        },
        {
            Header: selectedText.scope3 || 'Scope3',
            accessor: 'scope3',
            Cell: ({value}) => `${value}`,
            style: {backgroundColor: '#d2eed8'}
        },
        {
            Header: selectedText.scope4 || 'Scope4',
            accessor: 'scope4',
            Cell: ({value}) => `${value}`,
            style: {backgroundColor: '#d2eed8'}
        },
        {
            Header: selectedText.total || 'Total',
            accessor: 'total',
            Cell: ({value}) => `${value}`
        }
    ], [selectedText]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns: footprintSummaryColumns, data: footprintSummaryData});

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(selectedLanguage === 'FR' ? 'fr-FR' : 'en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const colors = [
        'rgba(255, 99, 132, 0.5)',
        'rgba(53, 162, 235, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(255, 159, 64, 0.5)'
    ];

    const barData = useMemo(() => ({
        labels: [
            selectedText.scope1 || 'Scope1',
            selectedText.scope2 || 'Scope2',
            selectedText.scope3 || 'Scope3',
            selectedText.scope4 || 'Scope4',
        ],
        datasets: footprintSummaryData.map((product, index) => ({
            label: product.product,
            data: [
                barGraphGWPUnit === 'kg' ? parseFloat(product.scope1) : parseFloat(product.scope1) / 1000,
                barGraphGWPUnit === 'kg' ? parseFloat(product.scope2) : parseFloat(product.scope2) / 1000,
                barGraphGWPUnit === 'kg' ? parseFloat(product.scope3) : parseFloat(product.scope3) / 1000,
                barGraphGWPUnit === 'kg' ? parseFloat(product.scope4) : parseFloat(product.scope4) / 1000,
            ],
            backgroundColor: colors[index % colors.length],
            borderColor: colors[index % colors.length].replace('0.5', '1'),
            borderWidth: 1,
        })),
    }), [footprintSummaryData, barGraphGWPUnit, selectedText]);

    const barOptions = {
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
            },
            datalabels: {
                display: true,
                formatter: (value) => Math.round(value)
            }
        },
        maintainAspectRatio: true,
        scales: {
            y: {
                title: {
                    display: true,
                    text: `GWP [${barGraphGWPUnit === 'kg' ? 'kg CO₂e' : 'tonne CO₂e'}]`,
                },
                ticks: {
                    callback: function (value) {
                        return barGraphGWPUnit === 'kg' ? value : (value / 1000).toFixed(1);
                    }
                }
            },
            x: {
                title: {
                    display: true,
                    text: selectedText.scopes || 'Scopes'
                }
            }
        },
        onClick: () => {
            setBarGraphGWPUnit((prevUnit) => (prevUnit === 'kg' ? 'ton' : 'kg')); // Toggle unit
        }
    };

    const calculateAverages = (data) => {
        const totals = {
            scope1: 0,
            scope2: 0,
            scope3: 0,
            scope4: 0,
            count: data.length
        };

        data.forEach(item => {
            totals.scope1 += parseFloat(item.scope1);
            totals.scope2 += parseFloat(item.scope2);
            totals.scope3 += parseFloat(item.scope3);
            totals.scope4 += parseFloat(item.scope4);
        });

        return {
            scope1: (totals.scope1 / totals.count).toFixed(2) || 0,
            scope2: (totals.scope2 / totals.count).toFixed(2) || 0,
            scope3: (totals.scope3 / totals.count).toFixed(2) || 0,
            scope4: (totals.scope4 / totals.count).toFixed(2) || 0
        };
    };

    const averages = calculateAverages(footprintSummaryData);
    console.log('Averages:', averages);

    const percentageDifference = (value1, value2) => {
        if (value1 === 0 || value2 === 0) {
            return 0;
        }
        const denominator = (value1 + value2) / 2;
        return denominator !== 0 ? (((value1 - value2) / denominator) * 100).toFixed(2) : 0;
    };

    const percentageDifferenceBarData = {
        labels: Object.keys(percentageDifferences),
        datasets: [{
            label: selectedText.percentageDifference || 'Percentage Difference',
            data: Object.values(percentageDifferences).map(value => parseFloat(value)),
            backgroundColor: Object.values(percentageDifferences).map(value => value < 0 ? 'rgba(75, 192, 192, 0.5)' : 'rgba(255, 99, 132, 0.5)'),
            borderColor: Object.values(percentageDifferences).map(value => value < 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)'),
            borderWidth: 1
        }]
    };

    const percentageDifferenceBarOptions = {
        indexAxis: 'y',
        scales: {
            x: {
                title: {
                    display: true,
                    text: selectedText.percentageDifferenceChart || 'Percentage Difference (%)'
                }
            },
            y: {
                title: {
                    display: true,
                    text: comparisonType === 'Value Chain' ? (selectedText.product || 'Product') : (selectedText.organization || 'Organization'),
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    generateLabels: function (chart) {
                        const datasets = chart.data.datasets;
                        return datasets[0].data.map((data, i) => ({
                            text: `${data < 0 ? selectedText.lessThan || '% less than' : selectedText.moreThan || '% more than'} ${comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)?.label}`,
                            fillStyle: data < 0 ? 'rgba(75, 192, 192, 0.5)' : 'rgba(255, 99, 132, 0.5)',
                            strokeStyle: data < 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)',
                            hidden: false,
                            index: i
                        }));
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.raw}%`;
                    }
                }
            }
        }
    };

    const downloadChartAsImage = (ref, filename) => {
        const chart = ref.current;
        if (chart) {
            const url = chart.toBase64Image();
            const link = document.createElement('a');
            link.href = url;
            link.download = `${filename}.png`;
            link.click();
        }
    };

    const generateReport = async () => {
        // Convert the first chart (barChartRef) to Base64 PNG
        const chartCanvas1 = barChartRef.current;
        const chartImageBase64_1 = chartCanvas1.toBase64Image(); // Base64 PNG string for the first chart

        // Convert the second chart (footprintChartRef) to Base64 PNG
        const chartCanvas2 = footprintChartRef.current;
        const chartImageBase64_2 = chartCanvas2.toBase64Image(); // Base64 PNG string for the second chart

        // Convert Base64 to an ArrayBuffer for DOCX
        const base64ToArrayBuffer = (base64) => {
            const binaryString = window.atob(base64.split(',')[1]);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        };

        const chartImageArrayBuffer_1 = base64ToArrayBuffer(chartImageBase64_1); // ArrayBuffer for the first chart
        const chartImageArrayBuffer_2 = base64ToArrayBuffer(chartImageBase64_2); // ArrayBuffer for the second chart

        // Extract headers from footprintSummaryColumns (assuming footprintSummaryColumns is your column config)
        const tableHeaders = [
            {text: comparisonType === 'Value Chain' ? (selectedText.product || 'Product') : (selectedText.organization || 'Organization')},
            {text: "Product Phase (kg CO₂e)"},
            {text: "Construction Phase (kg CO₂e)"},
            {text: "Use Phase (kg CO₂e)"},
            {text: "End-of-Life Phase (kg CO₂e)"},
            {text: "Benefits Phase (kg CO₂e)"},
            {text: "Total (kg CO₂e)"},
        ];

        // Extract data from footprintSummaryData
        const tableRows = footprintSummaryData.map(item => [
            {text: item.product},
            {text: item.productPhase ? `${item.productPhase} kg CO₂e` : '0 kg CO₂e'},
            {text: item.constructionPhase ? `${item.constructionPhase} kg CO₂e` : '0 kg CO₂e'},
            {text: item.usePhase ? `${item.usePhase} kg CO₂e` : '0 kg CO₂e'},
            {text: item.endOfLifePhase ? `${item.endOfLifePhase} kg CO₂e` : '0 kg CO₂e'},
            {text: item.benefitsPhase ? `${item.benefitsPhase} kg CO₂e` : '0 kg CO₂e'},
            {text: item.total ? `${item.total} kg CO₂e` : '0 kg CO₂e'},
        ]);

        const doc = new Document({
            sections: [
                {
                    children: [
                        // Heading 1: "Comparison Report"
                        new Paragraph({
                            text: "Comparison Report",
                            heading: "Heading1"
                        }),
                        // Existing table and phase data
                        new Table({
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [new Paragraph('Comparison Type')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Comparison Based On')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Selected Values')],
                                        }),
                                    ],
                                }),
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [new Paragraph(comparisonType)],
                                        }),
                                        new TableCell({
                                            children: [
                                                new Paragraph(comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)?.label || comparisonBasedOn),
                                            ],
                                        }),
                                        new TableCell({
                                            children: selectedValues.length > 0 ? selectedValues.map(value =>
                                                new Paragraph(comparisonBasedOnOptions.find(option => option.value === value)?.label || value)
                                            ) : [new Paragraph('-')],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                        // Adding a new line before the first Heading2
                        new Paragraph(""),

                        // Adding the phase comparison data
                        new Paragraph({
                            text: "Phase Comparison",
                            heading: "Heading2"
                        }),
                        new Table({
                            rows: [
                                // Header row
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [new Paragraph('Phase')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Current (kg CO₂e)')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Average (kg CO₂e)')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Difference (kg CO₂e)')],
                                        }),
                                    ],
                                }),
                                ...Object.keys(phaseKeys).map(phase => {
                                    const phaseKey = phaseKeys[phase];
                                    const currentRaw = parseFloat(selectedComparisonData[phaseKey]);
                                    const averageRaw = parseFloat(averages[phaseKey]);

                                    // Ensure we handle NaN properly by defaulting to 0
                                    const current = isNaN(currentRaw) ? 0 : currentRaw.toFixed(2);
                                    const average = isNaN(averageRaw) ? 0 : averageRaw.toFixed(2);
                                    const difference = Math.abs(current - average).toFixed(2);

                                    return new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph(phase)],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${current} kg CO₂e`)],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${average} kg CO₂e`)],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${difference} kg CO₂e`)],
                                            }),
                                        ],
                                    });
                                })
                            ],
                        }),
                        // Adding a new line before the next Heading2
                        new Paragraph(""),

                        // Adding the first chart image to the Word document
                        new Paragraph({
                            text: "Environmental Emission Chart",
                            heading: "Heading2"
                        }),
                        new Paragraph({
                            children: [
                                new ImageRun({
                                    data: chartImageArrayBuffer_1,
                                    transformation: {
                                        width: 600,  // Adjust the width as needed
                                        height: 300,  // Adjust the height as needed
                                    },
                                })
                            ]
                        }),
                        // Adding a new line before the next Heading2
                        new Paragraph(""),

                        // Adding the table from footprint data
                        new Paragraph({
                            text: "Footprint Summary",
                            heading: "Heading2"
                        }),
                        new Table({
                            rows: [
                                // Table headers
                                new TableRow({
                                    children: tableHeaders.map(header => new TableCell({
                                        children: [new Paragraph(header.text)],
                                    }))
                                }),
                                // Table rows with footprint data
                                ...tableRows.map(row => new TableRow({
                                    children: row.map(cell => new TableCell({
                                        children: [new Paragraph(cell.text)],
                                    })),
                                })),
                            ],
                        }),
                        // Adding a new line before the next Heading2
                        new Paragraph(""),

                        // Adding the second chart image (footprint chart) to the Word document
                        new Paragraph({
                            text: "Percentage Difference Chart",
                            heading: "Heading2"
                        }),
                        new Paragraph({
                            children: [
                                new ImageRun({
                                    data: chartImageArrayBuffer_2,
                                    transformation: {
                                        width: 200,  // Adjust the width as needed
                                        height: 100,  // Adjust the height as needed
                                    },
                                })
                            ]
                        }),
                    ],
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: 'Arivu.2024 | Copyright © 2024 VRTTA Green Solutions. All rights reserved.',
                                            font: 'Arial',
                                            size: 20, // font size
                                            italics: true,
                                        })
                                    ],
                                    alignment: 'center', // Center-align the footer text
                                })
                            ]
                        })
                    }
                },
            ],
        });

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, `Comparison_Report_${formattedDate}.docx`);
        });
    };

    return (
        <div className="comparison-page">
            <Header selectedLanguage={selectedLanguage}/>
            <div className="comparison-container">
                <div className="dropdown-container">
                    <Select
                        options={comparisonTypeOptions}
                        value={comparisonTypeOptions.find(option => option.value === comparisonType)}
                        onChange={selectedOption => setComparisonType(selectedOption.value)}
                        className="dropdown"
                        placeholder={selectedText.selectComparisonType || 'Select Comparison Type'}
                    />
                    <Select
                        options={comparisonBasedOnOptions}
                        value={comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)}
                        onChange={selectedOption => setComparisonBasedOn(selectedOption.value)}
                        className="dropdown"
                        placeholder={selectedText.comparisonBasedOn || 'Comparison Based On'}
                        isSearchable
                    />
                    <Select
                        isMulti
                        options={comparisonBasedOnOptions.filter(option => option.value !== comparisonBasedOn)}
                        value={comparisonBasedOnOptions.filter(option => selectedValues.includes(option.value))}
                        onChange={selectedOptions => setSelectedValues(selectedOptions.map(item => item.value))}
                        className="dropdown"
                        placeholder={selectedText.selectValuesToCompare || 'Select Values to Compare'}
                    />
                    <button onClick={handleApply} className="apply-button">
                        {selectedText.apply || 'Apply'}
                    </button>
                </div>
                <div className="phase-cards-container">
                    {Object.keys(phaseKeys).map(phase => {
                        const phaseKey = phaseKeys[phase];
                        return (
                            <PhaseCard
                                key={phase}
                                phase={phase}
                                current={parseFloat(selectedComparisonData[phaseKey]).toFixed(2) || 0}
                                average={parseFloat(averages[phaseKey]).toFixed(2) || 0}
                                isLess={(parseFloat(selectedComparisonData[phaseKey]).toFixed(2) || 0) < (parseFloat(averages[phaseKey]).toFixed(2) || 0)}
                                selectedLanguage={selectedLanguage}
                            />
                        );
                    })}
                </div>
                <div className="comparison-bar-chart">
                    <h2 className="h2-comparison">{selectedText.environmentalFootprint || 'Environmental Emission'}</h2>
                    <Bar ref={barChartRef} data={barData} options={barOptions}/>
                    <div className="download-icon"
                         onClick={() => downloadChartAsImage(barChartRef, 'environmental_footprint')}>
                        <Icon icon="fe:download" style={{color: 'black', cursor: 'pointer'}}/>
                    </div>
                </div>
                <div className="footprint-section">
                    <div className="footprint_table-container">
                        <h2>{selectedText.footprintSummary || 'Footprint Summary'}</h2>
                        <table {...getTableProps()} className="footprint-table">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th key={column.id} {...column.getHeaderProps()}
                                            style={column.style}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr key={row.id} {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td key={cell.id} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        <div className="units-info-comparison">
                            {selectedText.allUnits || 'All units are calculated in kg CO₂e'}
                        </div>
                    </div>
                    <div className="footprint-graph-container">
                        <h2 className="h2-comparison">{selectedText.percentageDifference || 'Percentage Difference'}</h2>
                        <Bar ref={footprintChartRef} data={percentageDifferenceBarData}
                             options={percentageDifferenceBarOptions}/>
                        <div className="download-icon"
                             onClick={() => downloadChartAsImage(footprintChartRef, 'percentage_difference')}>
                            <Icon icon="fe:download" style={{color: 'black', cursor: 'pointer'}}/>
                        </div>
                    </div>
                </div>
                <div className="inventory-date-container-comparison">
                    <button onClick={generateReport} className="generate-report-button-comparison">
                        <FiDownload size={18} style={{marginRight: '8px'}}/>
                        {selectedText.exportDocx || 'Generate Report'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default OrganizationalComparison;
