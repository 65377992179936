import '../style/components-style/NetZeroBottomTool.css'
import React, {useEffect, useState} from "react";
import config from "../config";

function NetZeroBottomTool({selectedText, selectedRow}) {
    const [isOpen, setIsOpen] = useState(true);
    const [baseYear, setBaseYear] = useState("");
    const [targetYear, setTargetYear] = useState("");
    const [sectorPathway, setSectorPathway] = useState("");
    const [scope1Emission, setScope1Emission] = useState(0.0);
    const [scope2Emission, setScope2Emission] = useState(0.0);
    const [scope3Emission, setScope3Emission] = useState(0.0);

    const [selectedTargetCoverage, setSelectedTargetCoverage] = useState('');

    const baseYearList = Array.from({length: 11}, (_, i) => (2015 + i).toString());
    const targetYearList = Array.from({length: 26}, (_, i) => (2025 + i).toString());
    const sectorPathwayList = ["Cross-sector pathway",
        "FLAG (Agriculture only)",
        "Iron and steel",
        "Cement",
        "Services - Buildings",
        "Residential - Buildings"];

    const toggleContainer = () => setIsOpen(!isOpen);

    const totalEmissions = scope1Emission + scope2Emission + scope3Emission;

    useEffect(() => {
        if (!selectedRow || !selectedRow.id) return;

        fetch(`${config.apiUrl}/api/get-emission-values-by-scope?row_id=${selectedRow.id}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error("Failed to fetch emissions data");
            }
            return response.json();
        })
        .then((data) => {
            setScope1Emission(data.scope1 / 1000 || 0.0);
            setScope2Emission(data.scope2 / 1000 || 0.0);
            setScope3Emission(data.scope3 / 1000 || 0.0);
        })
        .catch((error) => {
            console.error("Error fetching emissions data:", error);
        });
    }, [selectedRow]);

    return (
        <div className="absolute-target-section">
            <div className="net-zero-tool-header">
                <h2
                    onClick={toggleContainer}
                    onMouseEnter={() => document.querySelector('.net-zero-tool-header h2').style.color = 'grey'}
                    onMouseLeave={() => document.querySelector('.net-zero-tool-header h2').style.color = 'black'}
                    style={{cursor: 'pointer'}}
                >
                    {selectedText.netZeroToolTitle || 'Net Zero Tool'}
                </h2>
            </div>
            {/* Section 1.1 - Input Data */}
            {isOpen && (<div className="tool-section">
                <div className="section">
                    <h3>Section 1.1. Input data (absolute targets)</h3>
                    <div className="data-table">
                        <div className="table-row">
                            <div className="row label">Target coverage</div>
                            <select
                                className="input grey"
                                value={selectedTargetCoverage}
                                onChange={(e) => setSelectedTargetCoverage(e.target.value)}>
                                <option value="">Select an option</option>
                                <option value="Scope 1">Scope 1</option>
                                <option value="Scope 1 & 2">Scope 1 & 2</option>
                                <option value="Scope 3">Scope 3</option>
                                <option value="Scope 1,2 & 3">Scope 1,2 & 3</option>
                            </select>
                        </div>
                        <div className="table-row">
                            <div className="row label">Target setting method</div>
                            <div className="input grey">Absolute contraction</div>
                        </div>
                        <div className="table-row">
                            <div className="row label">Base year</div>
                            <select
                                className="input grey"
                                value={baseYear}
                                onChange={(e) => setBaseYear(e.target.value)}
                            >
                                <option value="">Select a Year</option>
                                {baseYearList.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="table-row">
                            <div className="row label">Target year</div>
                            <select
                                className="input grey"
                                value={targetYear}
                                onChange={(e) => setTargetYear(e.target.value)}
                            >
                                <option value="">Select a Year</option>
                                {targetYearList.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="table-row">
                            <div className="row label">Sector pathway</div>
                            <select
                                className="input grey"
                                value={sectorPathway}
                                onChange={(e) => setSectorPathway(e.target.value)}
                            >
                                <option value="">Select a Sector Pathway</option>
                                {sectorPathwayList.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="table-row">
                            <div className="row label">Scope 1 emissions</div>
                            <div className="input grey">{scope1Emission} tCO₂e</div>
                        </div>
                        <div className="table-row">
                            <div className="row label">Scope 2 emissions</div>
                            <div className="input grey">{scope2Emission} tCO₂e</div>
                        </div>
                        <div className="table-row">
                            <div className="row label">Scope 3 emissions</div>
                            <div className="input grey">{scope3Emission} tCO₂e</div>
                        </div>
                        <div className="table-row">
                            <div className="row label">Total emissions in 0 (tCO₂e)</div>
                            <div className="input grey">{totalEmissions.toFixed(2)} tCO₂e</div>
                        </div>
                    </div>
                </div>

                {/* Section 1.2 - Absolute Target Results */}
                <div className="section">
                    <h3>Section 1.2. Absolute target results</h3>
                    <div className="data-table">
                        <div className="table-row table-header">
                            <div className="header-column"></div>
                            <div className="header-column">Base year ()</div>
                            <div className="header-column">Target year ()</div>
                            <div className="header-column">% Intensity Reduction</div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Company | 0 intensity (tCO₂e/unit of value added)</div>
                            <div className="input grey">{baseYear || "0.00"}</div>
                            <div className="input grey">{targetYear || "#N/A"}</div>
                            <div className="input grey">#N/A</div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Long Term SBT formulation</div>
                            <div className="input grey">{baseYear || "0.00"}</div>
                            <div className="input grey">{targetYear || "#N/A"}</div>
                            <div className="input grey">#N/A</div>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    );
}

export default NetZeroBottomTool;