import React, { useState, useEffect, useMemo, useRef } from "react";
import "../style/organizational-pages-style/organizationalorganization.css";
import { FiDownload } from "react-icons/fi";
import { translations } from "../app_components/Translation";

// Components
import Header from "../app_components/Header";
import TableComponent from "../environment-page-components/Table";
import AssetsTable from "./fleet-pages-components/AssetsTable";
import FleetMetrics from "./fleet-pages-components/MetricCharts";
import UtilizationAnalysis from "./fleet-pages-components/UtilizationAnalysis";
import GenerateReportButton from "./fleet-pages-components/GenerateReportButton";

// utils
import { generatePDF } from "../utils/fleetUtils/GeneratePDF";
import { roundMax, roundMin } from "../utils/fleetUtils/roundNumber";
import { chartOptions, thirdChartOptions } from "../utils/fleetUtils/chartOptions";
import PriorityTable from "./fleet-pages-components/PriorityTable";
import PerformanceByYear from "./fleet-pages-components/PerformanceByYear";
import TripEmissions from "./fleet-pages-components/TripEmissions";

function FleetPerformance({ selectedLanguage }) {
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [mainTableGridApi, setMainTableGridApi] = useState(null);
  const [trackView, setTrackView] = useState(false);
  const [allAssets, setAllAssets] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [priorityTable, setPriorityTable] = useState([]);
  const [chartDataArray, setChartDataArray] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const chartRefs = useRef([]);

  const refreshData = () => {
    mainTableGridApi.refreshCells();
  };

  let xAxiesArray = ["Distance travelled (km)", "Distance travelled (km)", "Year", "Year", "Year", "Year"];
  let yAxiesArray = ["Fuel economy (L/100km)", "Idling time (%)", "Distance travelled (km)/Engine run time (hr)", "Distance travelled (km)", "Fuel economy (L/100km)", "Idling time (%)"];

  const apiKey = "samsara_api_v4yzCt6mQpGraWC9sfXgu4Fs3dTi2M";
  const startDate = "2023-01-01";
  const endDate = "2023-12-31";
  const system = "metric";

  const fetchAssetsData = async () => {
    try {
      const urlBase = "https://gaddi-js-api.vrtta.green";
      const response = await fetch(`${urlBase}/samsara-proxy?apiKey=${apiKey}&startDate=${startDate}&endDate=${endDate}&system=${system}`);

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();

      if (!(Array.isArray(data[0]) && data[0].length > 0)) {
        localStorage.clear();
        throw new Error("No Data Found!");
      }

      const mappedData = data[0].map((item) => ({
        assetNo: item["Vehicle"],
        fuelEconomy: item["Fuel economy (L/100km)"],
        fuelConsumed: item["Fuel consumption (L)"],
        distanceTravelled: item["Distance travelled (km)"],
        engineRunTime: item["Engine run time (hr)"],
        idlingPercent: item["Idling time (%)"],
        ghgEmissions: (item["Fuel consumption (L)"] * 2.7).toFixed(2),
        year: item["Year"],
      }));

      setTableData(data[0]);
      setAllAssets(mappedData);
    } catch (error) {
      console.error("Error fetching asset data:", error);
    }
  };

  const fetchChartData = async () => {
    try {
      const urlBase = "https://gaddi-js-api.vrtta.green";
      const response = await fetch(`${urlBase}/samsara-proxy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          xAxiesArray,
          yAxiesArray,
          newTableData: filteredTableData,
        }),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      setPriorityTable(data[0]);
      const processedDataArray = data[1];

      const chartDataArray = processedDataArray.map((data) => {
        let xMax = 0;
        let yMax = 0;
        let xMin = Number.MAX_SAFE_INTEGER;
        let yMin = Number.MAX_SAFE_INTEGER;

        const colorMap = {
          red: "rgba(211, 96, 86, 1)",
          cyan: "rgba(83, 155, 202, 1)",
          green: "rgba(79, 193, 127, 1)",
          orange: "rgba(222, 160, 0, 1)",
        };

        data.datasets.datasets.forEach((dataset) => {
          dataset.pointRadius = 7;
          dataset.pointHoverRadius = 15;
          dataset.radius = 15;

          dataset.backgroundColor = dataset.backgroundColor.map((color) => colorMap[color] || color);

          dataset.data.forEach((item) => {
            if (item.y > yMax) yMax = item.y;
            if (item.x > xMax) xMax = item.x;
            if (item.x < xMin && item.x != null) xMin = item.x;
            if (item.y < yMin && item.y != null) yMin = item.y;
          });
        });

        return { ...data, xMax, yMax, xMin, yMin };
      });

      // Process and update chart data
      const updatedDataArray = chartDataArray.map((data, index) => {
        const xMax1 = roundMax(data.xMax * 1.05);
        const yMax1 = roundMax(data.yMax * 1.05);
        const yMin1 = roundMin(data.yMin * 0.9);
        return {
          ...data,
          options: index < 2 ? chartOptions(data.xMean, data.yMean, data.xStd, data.yStd, xMax1, yMax1, 0, yMin1, xAxiesArray[index], yAxiesArray[index]) : thirdChartOptions(data.yMean, data.xMax + 1, yMax1, data.xMin - 1, yMin1, xAxiesArray[index], yAxiesArray[index]),
        };
      });

      setChartDataArray(updatedDataArray);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    fetchAssetsData();
  }, []);

  useEffect(() => {
    if (selectedRowData.length > 0 && selectedRowData[0]?.asset_numbers) {
      const assetNumbers = selectedRowData[0].asset_numbers;
      const filteredData = tableData.filter((row) => assetNumbers.includes(row.Vehicle));
      setFilteredTableData(filteredData);
    } else {
      setFilteredTableData(tableData);
    }
  }, [selectedRowData, tableData]);

  useEffect(() => {
    if (filteredTableData.length === 0) return;
    fetchChartData();
  }, [filteredTableData]);

  const selectedText = translations[selectedLanguage];

  const handleAssetDataUpdate = (updatedData) => {
    setSelectedRowData((prevData) => ({ ...prevData, ...updatedData }));
  };

  const averages = useMemo(() => {
    const totalEconomy = allAssets.reduce((sum, asset) => sum + parseFloat(asset.fuelEconomy || 0), 0);
    const totalGHG = allAssets.reduce((sum, asset) => sum + parseFloat(asset.ghgEmissions || 0), 0);
    const totalVehicles = allAssets.length;

    return {
      "Average Economy": totalEconomy / totalVehicles || 0,
      "Average GHG": totalGHG / totalVehicles || 0,
    };
  }, [allAssets]);

  const idleHours = useMemo(() => {
    return allAssets.reduce((sum, asset) => sum + parseFloat(asset.idlingPercent || 0), 0).toFixed(2);
  }, [allAssets]);

  const fuelConsumptionGallons = filteredTableData.map((vehicle) => ({
    Vehicle: vehicle.Vehicle,
    FuelConsumptionGallons: vehicle["Fuel consumption (L)"],
  }));

  const cleanUpMake = (make) => {
    if (make) {
      return make.trim();
    }
    return "";
  };

  const uniqueMakesSet = new Set(filteredTableData.map((item) => cleanUpMake(item.Make)).filter((item) => item)); // Remove falsy values
  const uniqueMakesArray = Array.from(uniqueMakesSet);

  const getBackgroundColor = (make) => {
    switch (make.trim()) {
      case "VOLVO TRUCK":
        return "#FF69B4";
      case "MACK":
        return "#6A1B9A";
      case "FREIGHTLINER":
        return "#1874CD";
      case "KENWORTH":
        return "#76EE00";
      case "PETERBILT":
        return "#FF4500";
      case "WESTERN STAR":
        return "#EEC900";
      default:
        return "#6c6c6c";
    }
  };

  return (
    <div className="organizationalorganization">
      <div className="table-div">
        <Header selectedLanguage={selectedLanguage} />
        <div className="main-table-container">
          <TableComponent setSelectedRowData={setSelectedRowData} setMainTableGridApi={setMainTableGridApi} setTrackViewState={setTrackView} selectedLanguage={selectedLanguage} setAllTableRows={setAllAssets} childTableRows={selectedRowData} pageType={"fleet"} />
        </div>
        {!trackView && (
          <div className="process-env-container">
            <AssetsTable selectedRowData={selectedRowData} selectedLanguage={selectedLanguage} refreshData={refreshData} onAssetDataUpdate={handleAssetDataUpdate} allAssets={allAssets} />
          </div>
        )}
        <FleetMetrics tableData={filteredTableData} averages={averages} idleHours={idleHours} />
        {chartDataArray && chartDataArray.length > 0 && (
          <div>
            <UtilizationAnalysis chartDataArray={chartDataArray} fuelConsumptionGallons={fuelConsumptionGallons} chartRefs={chartRefs} style={{ marginBottom: "15px" }} />
            <PriorityTable priorityTable={priorityTable} style={{ marginBottom: "15px" }} />
            <PerformanceByYear chartDataArray={chartDataArray} uniqueMakesArray={uniqueMakesArray} getBackgroundColor={getBackgroundColor} chartRefs={chartRefs} style={{ marginBottom: "15px" }} />
            <TripEmissions />
            <GenerateReportButton tableData={filteredTableData} generatePdf={generatePDF} setIsGenerating={setIsGenerating} generatePDF={generatePDF} selectedText={selectedText} />
          </div>
        )}{" "}
      </div>
    </div>
  );
}

export default FleetPerformance;
